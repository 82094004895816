import { defineStore } from "pinia";
import type { WitchesRecord, WitchItemsRecord } from "~/types/pocketbase-types";

export default defineStore("userStore", () => {
  const userWitch = ref<WitchesRecord>();
  const userWitchItems = ref<WitchItemsRecord[]>([]);
  const { pocketbase, user } = storeToRefs(usePocketBase());

  watchEffect(() => {
    if (user.value?.id) {
      subscribeAndInitWitch();
      return;
    }

    unsubscribeAndResetWitch();
  });

  async function subscribeAndInitWitch() {
    if (!user.value) {
      userWitch.value = undefined;
      return;
    }

    try {
      userWitch.value =
        (await pocketbase.value
          .collection("witches")
          .getFirstListItem<WitchesRecord>(`user.id="${user.value?.id}"`)) ??
        null;

      await pocketbase.value
        .collection("witches")
        .subscribe<WitchesRecord>(userWitch.value.id, (e) => {
          userWitch.value = e.record;
        });
    } catch {
      return;
    }
  }

  async function unsubscribeAndResetWitch() {
    userWitch.value = undefined;
    return pocketbase.value.collection("witches").unsubscribe();
  }

  watch(
    () => userWitch.value?.id,
    (currentId, oldId) => {
      if (currentId === undefined) {
        userWitchItems.value = [];
        return;
      }

      if (currentId !== oldId) {
        subscribeAndInitWitchItems();
        return;
      }
    },
  );

  onUnmounted(() => {
    pocketbase.value.collection("witches").unsubscribe();
  });

  async function subscribeAndInitWitchItems() {
    console.log("subscribe items");
    userWitchItems.value = await pocketbase.value
      .collection("witch_items")
      .getFullList<WitchItemsRecord>({
        filter: `witch.id="${userWitch.value?.id}"`,
        expand: "item",
      });
  }

  async function updateUserWitch(newWitchData: Partial<WitchesRecord>) {
    if (!userWitch.value) {
      return;
    }

    await pocketbase.value
      .collection("witches")
      .update<WitchesRecord>(userWitch.value.id, newWitchData);
  }

  return {
    userWitch,
    userWitchItems,

    updateUserWitch,
    fetchWitchItems: subscribeAndInitWitchItems,
  };
});
